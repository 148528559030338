import React, { Component, useState, useEffect } from 'react';
import Select from 'react-select';
import Configdata from './Defaultdata';
class Contact extends Component {
    state = {
        name: "World",
        type: "1",
        Phone: null,
        msg: "",
        typeoption: null,
        expoption: null,
        resm: null,
        url: ""
    };
 options = [
  { value: '1', label: 'IT' },
  { value: '2', label: 'Non-IT' },
  { value: '3', label: 'Others' }
 ];

    typehandleChange(event: any) {
        const tv = event.value;

        this.setState({ typeoption: tv });
    }
    exphandleChange(event: any) {
        const ev = event.value;

        this.setState({ expoption: ev });
    }


    sendtoserver(vnm: any, vph: any, vtyp: any, vexp: any, vremarks: any) {
        const localurl = this.state.url;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: vnm, phone: vph, type: vtyp, exp: vexp, rmk: vremarks })
        };
        try {
            const promiseAwait = fetch(localurl + '/receive', requestOptions);
            alert('success');

        }
        catch (e) {
            debugger;


        }
    }
    setserverurl() {
        debugger;
        const appurl = Configdata.srvurl;

        this.setState({ url: appurl });
    }
    componentDidMount() {
        this.setserverurl();
        this.Checkphone();
       

    }
expops = [{}];
    Checkphone() {
        const localurl = this.state.url;
        const txtphone = document.getElementById('uphone') as HTMLInputElement;
        const vph = txtphone.value.trim();

        fetch(localurl + "/getresume/" + vph)
            .then((res) => res.json())
            .then((data) => {

                this.setState({ resm: data.length });

                if (data.length > 0)
                    alert('Already registered with this phone No');
                else
                    this.insertresume();

            }
            );
    }
    insertresume() {

        const txtnm = document.getElementById('uname') as HTMLInputElement;
        const vnm = txtnm.value.trim();
        const txtphone = document.getElementById('uphone') as HTMLInputElement;
        const vph = txtphone.value.trim();
        const txtremarks = document.getElementById('remarks') as HTMLInputElement;
        const vremarks = txtremarks.value.trim();
        const vtyp = this.state.typeoption;
        const vexp = this.state.expoption;

        if (vnm == null || vnm == undefined || vnm == '') {
            alert('Please Enter Name');
            return;
        }
        if (vph == null || vph == undefined || vph == '') {
            alert('Please Enter Phone No.');
            return;
        }
        this.sendtoserver(vnm, vph, vtyp, vexp, vremarks);

    }
    handleClick() {
        this.Checkphone();


    } 
 render() {
for (let i = 1; i <= 40; i++) {
  var opt = {value: i, label: i};
this.expops.push(opt);
}

  return (

 <div className="maindiv">
    <div className="top-dibv">
        <h1 className="top-container">GOSTEPSUP</h1>
    </div>
    <div className="containerdiv">
        <div  className="centerdndiv">
            <div className="innerdiv">
                <div className="separate">
                    <span className="lable">
                        Name:
                    </span>
                    <span className="lable">
                              <input id="uname" type="text"  className="textbox-name-top" />
                    </span>
                 </div>
                <div className="separate">
                    <span className="lable">
                        Type:&nbsp;&nbsp;
                    </span>
                   <span className="lable">
                              <Select onChange={e => this.typehandleChange(e)} id="utype" options={this.options} className="textbox-name"/>

		  </span>
                  </div>
                <div className="separate">
                    <span className="lable">
                        Phone:
                    </span>
                    <span className="lable">
                              <input type="text" id="uphone" className="textbox-phone" />
                    </span >
                    <span className="lable">
                        Exp:
                    </span>
                        <span className="lable">
                              <Select onChange={e => this.exphandleChange(e)} options={this.expops} className="textbox-phone"/>

		  	</span>
             
                 </div>
                      <div className="separate">
                          <span className="lable">
                              Remarks:
                          </span>
                          <span className="lable">
                              <input type="text" id="remarks" className="textbox-name-top" />
                          </span>
                      </div>
                <div className="separate">
                    <span className="lable">
                        &nbsp;&nbsp;
                    </span>

                    <span className="submit">
                              <button onClick={this.handleClick.bind(this)} >submit</button>
                    </span>
                </div>
		<div> Connect.getstepsup@outlook.com</div>
            </div>

       

        </div>
    </div>

</div>


      );
}
}
export default Contact;
