import React, { Component, useState, useEffect } from 'react';
import Select from 'react-select';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { debug } from 'util';
import Scripts from './Scripts';
import Header from './Header';
import Jobsearch from './Jobsearch';
import Configdata from './Defaultdata';
export default class Login extends Component {
    state = {
        uname: "",
        psw: "",
        url:""
    };

   
    handleScriptsChange = () => {
        debugger;
        const rolid = localStorage.getItem("role");
        const element = <Scripts />;
        const jobelement = <Jobsearch />;
       
        const homediv = document.getElementById('container') as HTMLElement;
        if (rolid == "1")
            ReactDOM.render(element, document.getElementById('container'));
        else if (rolid == "2")
            ReactDOM.render(jobelement, document.getElementById('container'));
    };

    
    componentDidMount() {
        this.setserverurl();
        this.Checkuser();

       
    }
    Checkuser() {
        debugger;
        const txtnm = document.getElementById('uname') as HTMLInputElement;
        const vnm = txtnm.value.trim();
        const txtpass = document.getElementById('password') as HTMLInputElement;
        const vpass = txtpass.value.trim();
        const localurl = this.state.url;
        fetch(localurl + "/Login/" + vnm + "/" + vpass )
            .then((res) => res.json())
            .then((data) => {

                this.setState({ resm: data.length });

                if (data.length > 0) {
                    debugger;
                    localStorage.setItem("userid", vnm);
                    const uname = data[0].uname;
                    const id = data[0].id;
                    const roleid = data[0].role_id;
                    localStorage.setItem("id", id);
                    localStorage.setItem("role", roleid);
                    alert("login success");
                   
                   
                    const lbluname = document.getElementById('welcome') as HTMLElement;
                    lbluname.innerHTML = 'Welcome ' + uname;
                    /* var uid = this.handleScriptsChange();*/

                    this.handleScriptsChange();

                }
                else {
                    alert('Wrong user id or password');
                }
                 

            }
            );
    }
   
    handleClick() {
        this.setserverurl();
        this.Checkuser();
        
                  
    } 
    setserverurl() {
        debugger;
        const appurl = Configdata.srvurl;
        this.setState({ url: appurl });
    }
   
 render() {

    
  return (
<form>
 <div className="maindiv">
  
    <div className="containerdiv">
                  <div className="logindiv">
                      <table>
                          <tr>
                              <td>
                              UID:
                              </td>
                              <td>
                                  <input type="text" id="uname" className="textbox-name-top" />
                              </td>
                          </tr>
                          <tr>
                              <td>
                              Password:
                              </td>
                              <td>
                                  <input type="text" id="password" className="textbox-name-top" />
                              </td>
                          </tr>
                          <tr>
                           <td>
                          &nbsp;
                          </td>
                              <td>
                                  <button className="loginbutton" onClick={this.handleClick.bind(this)} >Login</button>
                              </td>
                          </tr>
                      </table>
            {/*<div className="innerdiv-login">*/}
            {/*    <div className="separate">*/}
            {/*        <span className="lable">*/}
            {/*                      User ID:&nbsp;&nbsp;&nbsp;*/}
            {/*        </span>*/}
            {/*        <span className="lable">*/}
            {/*                  <input type="text" id="uname" className="textbox-name-top" />*/}
            {/*        </span>*/}
            {/*     </div>*/}
                
                
            {/*              <div className="separate">*/}
            {/*                  <span className="lable">*/}
            {/*                      Password:*/}
            {/*                  </span>*/}
            {/*                  <span className="lable">*/}
            {/*                      <input type="text" id="password" className="textbox-name-top" />*/}
            {/*                  </span>*/}
            {/*              </div>*/}
            {/*    <div className="separate">*/}
            {/*        <span className="lable">*/}
            {/*            &nbsp;&nbsp;*/}
            {/*        </span>*/}

            {/*        <span className="submit">*/}
            {/*                  <button onClick={this.handleClick.bind(this)} >Login</button>*/}
            {/*        </span>*/}
            {/*    </div>*/}
                         
                         
            {/*</div>*/}

       

        </div>
    </div>

</div>
</form>

      );
}
}

