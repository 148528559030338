import React, { Component, useState, useEffect } from 'react';
import Select from 'react-select';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { debug } from 'util';
import Check from './Check';
import Configdata from './Defaultdata';

import './table.css';
export default class Scripts extends Component {
    state = {
        name: "World",
        type: "1",
        Phone: null,
        msg: "",
        typeoption: null,
        catoption: null,
        snameoptionval: null,
        snameoptionlbl: null,
        expoption: null,
        resm: null,
        viewtype: null,
        scrname: "",
        scrcode: "",
        scrremarks: "",
        scrcapital:null,
        Scripts: [{ id: '0', sname: "Statebank of India", scode: 'SBI', Capitas: '55' }],
        Cats: [{ value: '0', label: "OTHER" }],
        Snames: [{ value: '0', label: "OTHER" }],
        Scrptstatus: [{ id: '0', sname: "Statebank of India", min: '', mindate: '', max: '', maxdate: '', year: '',oth:'' }]
    };
    usrps = [
        { id: '0', sname: "Statebank of India", scode: 'SBI', Capitas: '55' }

    ];
    scrstatus = [
        { id: '0', sname: "Statebank of India", min: '', mindate: '', max: '', maxdate: '',year:'' }

    ];
 options = [
  { value: '1', label: 'Above 1L' },
  { value: '2', label: 'Above 50T Bellow 1L' },
     { value: '3', label: 'Above 10T Bellow 50T' },
     { value: '4', label: 'Above 1T Bellow 10T' },
     { value: '5', label: 'Bellow 1T' }
 ];
    category = [
        { value: '0', label: 'Other' }
       
    ];
    arrnames = [
        { value: '0', label: 'Other' }

    ];
    expops = [{}];
    typehandleChange(event: any) {
        const tv = event.value;
       
        this.setState({ typeoption: tv });
    }
   
    categoryhandleChange(event: any) {
        const tv = event.value;

        this.setState({ catoption: tv });
    }
    SnamehandleChange(event: any) {
        const tv = event.value;
        const tl = event.label;

        this.setState({ snameoptionval: tv });
        this.setState({ snameoptionlbl: tl });
    }
    sendtoserver(vnm: any, vcode: any, vtyp: any, vremarks: any, Capitals: any,ctype: any) {
       
        const id = localStorage.getItem("id");
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code: vcode, name: vnm, type: vtyp, caps: Capitals, desc: vremarks,uid: id,cid: ctype })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/Scriptinput', requestOptions);
            alert('Saved successfully');
          
            this.Viewscripts();
            this.BindScriptnames();

            this.clearscript();
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    sendstatustoserver(vnm: any, vid: any, min: any, max: any, mindate: any, maxdate: any,year: any,remarks: any) {

        const id = localStorage.getItem("id");

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sname: vnm, sid: vid, min: min, max: max, mindate: mindate, maxdate: maxdate, year: year,remarks:remarks,uid: id })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/Scriptstatusinput', requestOptions);
            alert('Saved successfully');

            this.Viewscriptstatus();
            this.BindScriptnames();

            this.clearscript();
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    Viewscripts() {
       
        this.setState({ viewtype: 'scr' });
       
        debugger;
        //const element = <Userscripts />;
       // const div = document.getElementById('userscriptdiv') as HTMLElement;
       // div.innerHTML = '';
        // ReactDOM.render(element, div);
      const  lusrps = [
            { id: '0', sname: "Statebank of India", scode: 'SBI', Capitas: '55' }

        ];
        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/userscript/" + id)
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { id: '', sname: '', scode: '', Capitas: '' };


                    us.Capitas = u.Capitas.toString();
                    us.sname = u.sname.toString();
                    us.scode = u.scode.toString();
                    us.id = u.id.toString();
                    lusrps.push(us);


                })
                this.setState({ Scripts: lusrps });
               // setuscripts(usrps);
            }
            );
    }
    Viewscriptstatus() {
        this.setState({ viewtype: 'sts' });
        debugger;
       
        const lusrps = [{ id: '0', sname: "Statebank of India", min: '0', mindate: '2023-01-01', max: '0', maxdate: '2023-01-01', year: '2023',oth:'heigh' }];
        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/getscriptstatus/" + id)
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { id: '0', sname: "Statebank of India", min: '0', mindate: '2023-01-01', max: '0', maxdate: '2023-01-01', year: '2023', oth: 'heigh' };
                    us.id = u.id;
                    us.sname = u.sname;
                    us.min = u.min;
                    us.mindate = u.mindate;
                    us.max = u.max
                    us.maxdate = u.maxdate;
                    us.year = u.yr;
                    us.oth = u.remarks;

                    lusrps.push(us);


                })
                this.setState({ Scrptstatus: lusrps });
                // setuscripts(usrps);
            }
            );
    }
    BindCategory() {
        debugger;
        //const element = <Userscripts />;
        // const div = document.getElementById('userscriptdiv') as HTMLElement;
        // div.innerHTML = '';
        // ReactDOM.render(element, div);

        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/Category")
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { value: '', label: ''};


              
                    us.label = u.name.toString();
               
                    us.value = u.id.toString();
                this.category.push(us);


                })
                this.setState({ Cats: this.category });
               
            }
            );
    }

    BindScriptnames() {
        debugger;
       
       const lnames = [
            { value: '0', label: 'Other' }

        ];
        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/getscripts")
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { value: '', label: '' };



                    us.label = u.sname.toString();

                    us.value = u.id.toString();
                    lnames.push(us);


                })
                this.setState({ Snames: lnames });

            }
            );
    }
    componentDidMount() {
        this.BindCategory();
        this.Checkscript();
        this.Viewscripts();
        this.disablealldiv();
        this.BindScriptnames();
      
        this.Viewscriptstatus();

    }
    Checkscript() {
        
        const txtcode = document.getElementById('Code') as HTMLInputElement;
        const vcode = txtcode.value.trim();
        if (vcode != null && vcode != '' && vcode != undefined) {
            fetch(Configdata.srvurl + "/gescript/" + vcode)
                .then((res) => res.json())
                .then((data) => {
                  
                    this.setState({ resm: data.length });

                    if (data.length > 0)
                        alert('Already Script exist');
                    else
                        this.insertscript();

                }
                );
        }
      
    }
    clearscript() {
        const frm = document.getElementById("insertscriptsts") as HTMLFormElement;
        frm.reset();
   
    }
    insertscript() {
        const txtnm = document.getElementById('sname') as HTMLInputElement;
        const vnm = txtnm.value.trim();
        const txtcode = document.getElementById('Code') as HTMLInputElement;
        const vcode = txtcode.value.trim();
        const txtremarks = document.getElementById('remarks') as HTMLInputElement;
        const vremarks = txtremarks.value.trim();
       
        const txtcaps = document.getElementById('scaps') as HTMLInputElement;
        const vcaps = txtcaps.value.trim();
        const vtyp = this.state.typeoption;
        const ctyp = this.state.catoption;
        const caps = parseFloat(vcaps);
           if (vnm == null || vnm == undefined || vnm == '') {
            alert('Please Enter Name');
            return;
        }
        if (vcode == null || vcode == undefined || vcode == '') {
            alert('Please Enter Code.');
            return;
        }
        this.sendtoserver(vnm, vcode, vtyp, vremarks, caps, ctyp);
       
    }
    insertscriptstatus() {
       
        const vsname = this.state.snameoptionlbl;
        const vsid = this.state.snameoptionval;
       
        const txtmin = document.getElementById('min') as HTMLInputElement;
        const vmin = txtmin.value.trim();
        const txtmax = document.getElementById('max') as HTMLInputElement;
        const vmax = txtmax.value.trim();

        const txtmindate = document.getElementById('mindate') as HTMLInputElement;
        const vmindate = txtmindate.value.trim();

        const txtmaxdate = document.getElementById('maxdate') as HTMLInputElement;
        const vmaxdate = txtmaxdate.value.trim();
        const txtyear = document.getElementById('year') as HTMLInputElement;
        const vyear = txtyear.value.trim();

        const txtremarks = document.getElementById('stsremarks') as HTMLInputElement;
        const vremarks = txtremarks.value.trim();

       
        
        if (vsid == null || vsid == undefined || vsid == '') {
            alert('Please select Name');
            return;
        }
        if (vyear == null || vyear == undefined || vyear == '') {
            alert('Please Enter Year.');
            return;
        }
        this.sendstatustoserver(vsname, vsid, vmin, vmax,vmindate,vmaxdate,vyear,vremarks);

    }
    handleClick() {
     
        this.Checkscript();
        
                  
    } 
    handlestatusClick() {

        this.insertscriptstatus();


    } 
    disablealldiv() {
        const scriptdiv = document.getElementById('scriptentryform') as HTMLElement;
        scriptdiv.style.display = 'none';
        const statusdiv = document.getElementById('statusform') as HTMLElement;
        statusdiv.style.display = 'none';
        const statusgrid = document.getElementById('statusgrid') as HTMLElement;
        statusgrid.style.display = 'none';
        const scriptgrid = document.getElementById('scriptgrid') as HTMLElement;
        scriptgrid.style.display = 'none';

    }
    activeScriptentrty() {
        this.disablealldiv();
        const scriptdiv = document.getElementById('scriptentryform') as HTMLElement;
        scriptdiv.style.display = 'block';
      
        
    }
    activeScriptstatus() {
        this.disablealldiv();
        const scriptdiv = document.getElementById('statusform') as HTMLElement;
        scriptdiv.style.display = 'block';

    }
    activeScriptentrtygrid() {
        debugger;
        this.disablealldiv();
       
        const scriptgrid = document.getElementById('scriptgrid') as HTMLElement;
        scriptgrid.style.display = 'block';

    }
    activeScriptstatugrids() {
        this.disablealldiv();
       
        const statusgrid = document.getElementById('statusgrid') as HTMLElement;
        statusgrid.style.display = 'block';
    }
 render() {

    
  return (
<form id="insertscriptsts">
 <div className="maindiv">
    <div className="top-dibv-scr">
                  <div>
                      <span className="border-p">
                          <button onClick={this.activeScriptentrty.bind(this)}>SCRIPTS</button>
                      </span>
                      <span className="border-p">
                          <button onClick={this.activeScriptstatus.bind(this)}>STATUS</button>
                      </span>
                      
                  </div>   
              </div>
    
              <div id="scriptentry" className="containerdiv">
                  <div id="scriptentryform" className="left-center-div">
                      <div className="containerdiv">
            <div className="innerdiv-scr">
                <div className="separate">
                    <span className="lable">
                        Name:
                    </span>
                    <span className="lable">
                                      <input type="text" id="sname" className="textbox-name-top" />
                    </span>
                          </div>
                          <div className="separate">
                              <span className="lable">
                                 Code:
                              </span>
                              <span className="lable">
                                  <input type="text" id="Code" className="textbox-name-top" />
                              </span >


                          </div>
                <div className="separate">
                    <span className="lable">
                        Type:&nbsp;&nbsp;
                    </span>
                   <span className="lable">
                                  <Select onChange={e => this.typehandleChange(e) } id="utype" options={this.options} className="textbox-name"/>

		  </span>
                              </div>
                              <div className="separate">
                                  <span className="lable">
                                      Cats:&nbsp;
                                  </span>
                                  <span className="lable">
                                      <Select onChange={e => this.categoryhandleChange(e)} id="ctype" options={this.state.Cats} className="textbox-name" />

                                  </span>
                              </div>
                <div className="separate">
                    <span className="lable">
                      Caps:
                    </span>
                    <span className="lable">
                                  <input type="text" id="scaps" className="textbox-name-top" />
                    </span >
                   
             
                 </div>
                          <div className="separate">
                              <span className="lable">
                                  Oths:
                              </span>
                              <span className="lable">
                                  <input type="text" id="remarks" className="textbox-name-top" />
                              </span>
                          </div>
                 <div className="separate">
                  
                    <span className="middle-button">
                                      <button onClick={this.handleClick.bind(this)} >Submit</button>
                                      <button onClick={this.activeScriptentrtygrid.bind(this)} >View</button>
                    </span>
                                  <span className="middle-button">
                                     
                                  </span>
                  
                </div>
                          
                         
            </div>

       

             </div>
          </div>
                  <div id="scriptgrid" className="left-div">
                      <div id="userscriptdiv" className="containtdiv-tbl">
                          <div>

                              <table className="main-table">
                                  <tr>

                                      <th className="main-th">Script Name</th>
                                      <th className="main-th">Script Code</th>
                                      <th className="main-th">Market Capitals.</th>

                                  </tr>
                                  <tbody>

                                      {this.state.Scripts.map((s) => (
                                          <tr>
                                              <td className="main-td">
                                                  {s.sname}
                                              </td>
                                              <td className="main-td">
                                                  {s.scode}
                                              </td>
                                              <td className="main-td">
                                                  {s.Capitas}
                                              </td>
                                          </tr>
                                      ))}


                                  </tbody>
                              </table>

                          </div>

                      </div>
            </div>
              </div>


              <div id="statusentry" className="containerdiv">

                  <div id="statusform" className="left-center-div">
                      <div className="containerdiv">
                          <div className="innerdiv-scr">
                              <div className="separate">
                                  <span className="lable">
                                      Name:
                                  </span>
                                  <span className="lable">
                                      <Select onChange={e => this.SnamehandleChange(e)} id="ssnames" options={this.state.Snames} className="textbox-name" />
                                  </span>
                              </div>
                              <div className="separate">
                                  <span className="lable">
                                      Min:&nbsp;
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="min" className="textbox-name-top" />
                                  </span >


                              </div>
                              <div className="separate">
                                  <span className="lable">
                                      Date:
                                  </span>
                                  <span className="lable">
                                      <input placeholder="yyyy-mm-dd" type="text" id="mindate" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div className="separate">
                                  <span className="lable">
                                      Max:&nbsp;
                                  </span>
                                  <span className="lable">

                                      <input type="text" id="max" className="textbox-name-top" />
                                  </span>
                              </div>
                              <div className="separate">
                                  <span className="lable">
                                      Date:
                                  </span>
                                  <span className="lable">
                                      <input placeholder="yyyy-mm-dd" type="text" id="maxdate" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div className="separate">
                                  <span className="lable">
                                      Year:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="year" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div className="separate">
                                  <span className="lable">
                                      Oths:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="stsremarks" className="textbox-name-top" />
                                  </span>
                              </div>
                              <div className="separate">

                                  <span className="middle-button">
                                      <button onClick={this.handlestatusClick.bind(this)} >Submit</button>
                                      <button onClick={this.activeScriptstatugrids.bind(this)} >View</button>
                                  </span>
                                  <span className="middle-button">

                                  </span>

                              </div>


                          </div>



                      </div>
                  </div>
                  <div id="statusgrid" className="left-div">
                      <div id="statuscriptdiv" className="containtdiv-tbl">
                          <div>

                              <table className="main-table">
                                  <tr>

                                      <th className="main-th">Script Name</th>

                                      <th className="main-th">Min value</th>

                                      <th className="main-th">Min Date</th>

                                      <th className="main-th">Max value</th>

                                      <th className="main-th">Max Date</th>
                                      <th className="main-th">Year</th>
                                      <th className="main-th">Remarks</th>
                                  </tr>
                                  <tbody>

                                      {this.state.Scrptstatus.map((s) => (
                                          <tr>
                                              <td className="main-td">
                                                  {s.sname}
                                              </td>
                                              <td className="main-td">
                                                  {s.min}
                                              </td>
                                              <td className="main-td">
                                                  {s.mindate}
                                              </td>
                                              <td className="main-td">
                                                  {s.max}
                                              </td>
                                              <td className="main-td">
                                                  {s.maxdate}
                                              </td>
                                              <td className="main-td">
                                                  {s.year}
                                              </td>
                                              <td className="main-td">
                                                  {s.oth}
                                              </td>
                                          </tr>
                                      ))}


                                  </tbody>
                              </table>

                          </div>

                      </div>
                  </div>
                  
                  
              </div>

</div>
</form>

      );
}
}
//export default Home;
