import React, { Component } from 'react';


class Footer extends Component {
 render() {
  return (
  	<div className="footerdiv">
	     &copy; Gostepsup
	</div>

      );
   }
}
export default Footer;
